import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom"; //Link as RouteLink
import { Link, animateScroll as scroll } from "react-scroll";
import './index.css';

import Clubs from './components/clubs'
import Interclub from './components/interclub'

function Page() {
  return (
    <div className="page">
      <img className='tmp' src='./Badminton-Wairarapa.jpg' alt='layout guide'></img>
      <Router>
        <header>
          <h1 className="title text-lg">Wairarapa Badminton</h1>
            <nav>
              <scroll>
              <Link
                className="navlink"
                activeClass="active"
                to="Clubs"
                spy={true}
                smooth={true}
                offset={-50}
                duration={200}
              >Clubs</Link>
              {/* <Link
                className="navlink"
                to="Interclub"
                spy={true}
                smooth={true}
                offset={-100}
                duration={200}
              >Interclub</Link> */}
              </scroll>
            </nav>
        </header>
        <Route path="/" exact component={FrontPage} />
      </Router>
    </div>
  );
}

function Footer() {
  return (
    <footer>
      <p className='footer'>©2021 Wairarapa Badminton | All Rights Reserved</p>
    </footer>
  );
}

function FrontPage() {
  return (
    <div className='frontpage'>
      <Clubs/>
      {/* <Interclub/> */}
      <Footer/>
    </div>
  );
}

ReactDOM.render(
  <Page />,
  document.getElementById('root')
);
