import './clubs.css';

function getClubs(){
    const clubs = [
        {
        "name": "Masterton",
        "clubnight": "Friday 7:00pm",
        "fee": "$5 per night",
        "address1": "Chanel College Hall",
        "address2": "Herbert Street, Masterton 5810",
        "hire": "Racquets are available at no extra charge",
        "courts": "5 Courts",
        "pin": [-40.960739, 175.653723],
        "glink": "https://www.google.co.nz/maps/place/40%C2%B057'38.7%22S+175%C2%B039'13.4%22E/@-40.9607741,175.653919,18.25z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-40.9607366!4d175.6537125"
        },
        {
        "name": "Clareville",
        "clubnight": "Tuesday 7:30pm",
        "fee": "$5 per night",
        "address1": "Clareville Complex",
        "address2": "12 Chester Road, RD 1, Carterton 5791",
        "pin": [-41.003672, 175.549848],
        "glink": "https://www.google.co.nz/maps/place/41%C2%B000'13.1%22S+175%C2%B032'59.6%22E/@-41.0036289,175.5493369,18z/data=!4m15!1m8!3m7!1s0x0:0x0!2zNDHCsDA2JzM5LjciUyAxNzXCsDE5JzQwLjQiRQ!3b1!7e2!8m2!3d-41.1110166!4d175.32788!3m5!1s0x0:0x0!7e2!8m2!3d-41.0036442!4d175.5498769"
        },
        {
        "name": "Gladstone",
        "clubnight": "Tuesday 7:30pm",
        "fee": "$4 per night or $60 per season",
        "address1": "Gladstone Sports Complex",
        "address2": "585 Gladstone Road, Gladstone 5792",
        "courts": "2 Courts",
        "pin": [-41.071662, 175.633636],
        "glink": "https://www.google.co.nz/maps/place/41%C2%B004'18.0%22S+175%C2%B038'01.1%22E/@-41.0722625,175.6329265,18.25z/data=!4m15!1m8!3m7!1s0x0:0x0!2zNDHCsDA2JzM5LjciUyAxNzXCsDE5JzQwLjQiRQ!3b1!7e2!8m2!3d-41.1110166!4d175.32788!3m5!1s0x0:0x0!7e2!8m2!3d-41.0716767!4d175.6336467"
        },
        {
        "name": "Featherston",
        "clubnight": "Tuesday 7:30pm",
        "fee": "$2 per night or $20 per season",
        "address1": "South Wairarapa Sports",
        "address2": "Underhill Road, Featherston 5710",
        "courts": "4 Courts",
        "pin": [-41.110993, 175.327838],
        "glink": "https://www.google.co.nz/maps/place/41%C2%B006'39.7%22S+175%C2%B019'40.4%22E/@-41.1110137,175.32829,18z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-41.1110166!4d175.32788"
        }
    ];
    return clubs
}
  
function Clubs() {
    const clubs = getClubs();
    return (
        <section className="page-clubs">
            <div className='clubstop'>
                <img className='clubsimg' src='badminton-wairarapa-header-image-resize.png' alt='title background'></img>
                <h3 className='findyour'>FIND YOUR</h3>
                <h2 className="page-title">WAIRARAPA BADMINTON <br/>SOCIAL CLUB</h2>
            </div>
            <div className='clubs' id='Clubs'>
            {clubs.map((club, index) => 
                <article key={club.name} className="club container">
                    <div className="club-header">
                        <h2 className="club-name">{club.name}</h2>
                        <hr className="club-line"/>
                    </div>
                    <ul className="club-list">
                        <li className={club.clubnight ? "club-line" : "disable" }>{club.clubnight}</li>
                        <li className={club.fee ? "club-line" : "disable" }>{club.fee}</li>
                        <li className={club.hire ? "club-line" : "disable" }>{club.hire}</li>
                        <li className={club.courts ? "club-line" : "disable" }>{club.courts}</li>
                        <li className={club.address1 ? "club-line" : "disable" }>{club.address1}</li>
                        <li className={club.address2 ? "club-line" : "disable" }>
                            {club.address2}
                        </li>
                        <li className={club.contact ? "club-line" : "disable" }>{club.contact}</li>
                    </ul>
                    <a className='loclink' href={club.glink} target="_blank" rel="noreferrer">
                        <img className='locimg' src='location-icon.png' alt='location icon'></img>
                    </a>
                </article>
            )}
            </div>
        </section>
    );
}

export default Clubs;
